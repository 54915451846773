@import '../../assets/scss/App.scss';

.feedback {
  &__container {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__section {
    border: 1px solid $gray3;
    border-radius: 4px;
    padding: 8px;
    background-color: white;
  }
  &__feedbackHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    @include breakpoint($mobile) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
  &__feedbackClient {
    font-size: 16px;
    line-height: 22px;
    color: $black;
    margin-right: 4px;
    @include breakpoint($mobile) {
      margin-right: 0;
    }
  }
  &__feedbackDate {
    display: block;
    text-align: right;
    font-size: 12px;
  }
  &__rate {
    width: 140px;
  }
}
