// breakpoints
$minimob: 320px;
$mobile: 740px;
$tablet: 940px;
$laptop: 1400px;
$desktop: 1920px;
// colors
// black
$black: #363636;
$lightBlack: #666666;
$dimGray: #595959;
$grayish: #7a7a7a;
$gray3: #b2b2b2;
$gray2: #d9d9d9;
$lightGray: #e5e5e5;
$gray: #f7f7f7;
$white: #fff;
$orange: #f05400;
// FONT WEIGHTS
$robotoRegular: 400;
$robotoMedium: 500;
$robotoBold: 700;
