.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  padding: 24px 16px;
  background-color: white;
}

.content {
  flex: 1 0 auto;
  @include breakpoint($mobile) {
    padding-top: 80px;
    margin: 0;
  }
  &--landing {
    padding: 0;
    margin: -25px -16px;
    @include breakpoint($mobile) {
      padding-top: 0;
    }
  }
}
