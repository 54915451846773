.terms {
  &__heading,
  &__header {
    margin-bottom: 16px;
  }
  &__name,
  &__subheader {
    margin-bottom: 8px;
  }
  &__date {
    display: block;
    font-size: 12px;
  }

  &__bold {
    font-weight: 700;
  }
  &__body {
    table,
    td,
    td {
      border: 1px solid black;
    }
    td {
      padding: 4px;
    }
    table {
      margin-bottom: 8px;

      width: 100%;
    }
  }
  &__tableWrapper {
    overflow-x: auto;
  }
}
