@import '../../assets/scss/App.scss';

.card {
  &__clientAndServicesContainer {
    display: flex;
    justify-content: space-between;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }
  &__info {
    display: flex;
    justify-content: space-between;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
    &-address,
    &-date {
      flex: 1;
    }
    &-duration {
      flex: 1;
      padding: 0 8px;
      @include breakpoint($mobile) {
        padding: 0;
      }
    }
    &-address,
    &-date,
    &-duration {
      line-height: 1.3;
    }
  }
  &__heading {
    margin-bottom: 8px;
  }
  &__client {
    width: 50%;
    margin-bottom: 0;
    @include breakpoint($mobile) {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  &__services {
    width: 50%;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
  &__service {
    // border: 1px solid $gray3;
    padding: 0 8px;
    // padding-bottom: 0;
    border-radius: 4px;
    margin-bottom: 8px;
    width: 100%;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint($mobile) {
      padding: 0;
    }
  }
  &__serviceName {
    font-weight: $robotoBold;
    color: black;
    line-height: 1.3;
    display: inline-block;
    margin-bottom: 0;
  }
  &__contractorName {
    @include breakpoint($mobile) {
      font-size: 14px;
    }
  }
  &__serviceItems,
  &__contractorName {
    margin-bottom: 0;
  }
  &__serviceItems {
    flex: 1;
  }
  &__icon {
    margin-right: 8px;
  }
  &__contractor {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__contractorContainer {
    display: flex;
    align-items: center;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }
  &__avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include breakpoint($mobile) {
      width: 30px;
      height: 30px;
    }
  }
  &__duration {
    display: flex;
    width: 150px;
    align-items: center;
  }
  &__durationInput {
    margin: 8px;
  }
  &__finishJob {
    text-align: right;
  }
  &__noteInput {
    margin: 8px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    // border: 1px solid black;
    border-top: 1px solid $gray3;
    padding-top: 16px;
  }

  &__header {
    display: flex;
    margin-right: 38px;
  }

  &__reportBtn {
    margin-left: auto;
  }
}
