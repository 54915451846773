.change_contractor {
  &__select {
    width: 100%;
  }
  &__image {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  &__btn {
    margin-left: auto;
  }
}
