@import '../../assets/scss/App.scss';

.image {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}
