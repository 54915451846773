@import '../../assets/scss/App.scss';

.order {
  // &__itemList {
  //   line-height: 22px;
  //   list-style-type: disc;
  //   padding-left: 16px;
  // }

  &__vault {
    font-size: 14px;
  }
}

.payment {
  &__button {
    border: 1px solid $gray3;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 49%;
    margin-bottom: 0;
    transition: all 200ms ease;
    height: 87px;
    &:hover {
      background-color: $orange;
      cursor: pointer;
      border: 1px solid transparent;
      .payment__name {
        color: $white;
      }
      svg {
        fill: $white;
        path {
          fill: $white;
        }
      }
    }
    &:focus {
      outline: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint($tablet) {
      width: 100%;
      margin-bottom: 18px;
    }
    svg {
      width: 34px;
      height: 35px;
      fill: black;
      path {
        fill: black;
      }
    }
    &--disabled {
      &:hover {
        background-color: inherit;
        border-color: $gray3;
        .payment__name {
          color: $gray3;
        }
        svg {
          fill: $gray3;
          path {
            fill: $gray3;
          }
        }
      }
      .payment__name {
        color: $gray3;
      }
      svg {
        fill: $gray3;
        path {
          fill: $gray3;
        }
      }
    }
  }
  &__buttonWrapper {
    width: 49%;
  }
  &__buttonWrapped {
    width: 100%;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint($tablet) {
      flex-direction: column;
    }
  }
  &__name {
    color: $dimGray;
    font-size: 16px;
    line-height: 22px;
    display: block;
    margin-left: 17px;
  }
}

.paymentIcons {
  height: 34px;
  @include breakpoint($mobile) {
    height: 16px;
  }
  &__container {
    margin: 24px 0 0 0;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
