@import '../../assets/scss/App.scss';

.note {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 8px;
    // margin-bottom: 8px;
    @include breakpoint($mobile) {
      flex-direction: column;
      margin-right: 0;
      // margin-bottom: 8px;
    }
  }
  &__text {
    padding-right: 8px;
    // padding-bottom: 8px;
    margin-bottom: 0;
    @include breakpoint($mobile) {
      padding-bottom: 8px;
      padding-right: 0;
    }
  }
}
