.form {
  &__wrapper {
    margin: 0 auto 0;
    // margin: 64px auto 0;
    width: 395px;
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
  &__section {
    border: 1px solid $gray3;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
}
.label {
  font-size: 14px;
  line-height: 22px;
  color: $grayish;
  font-weight: $robotoRegular;
  margin-left: 8px;
}
.login-form {
  margin-top: 16px;
}
