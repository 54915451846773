@import '../../assets/scss/App.scss';

.reportItem {
  display: flex;
  align-items: center;
  position: relative;
  @include breakpoint($mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__name {
    margin-bottom: 0;
    width: 400px;
    font-weight: $robotoBold;
    @include breakpoint($mobile) {
      width: 100%;
      // margin-bottom: 8px;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    @include breakpoint($mobile) {
      justify-content: space-between;
    }
    &--input {
      width: 70px;
      margin: 0 8px;
      @include breakpoint($mobile) {
        width: 45%;
        margin: 0;
      }
    }
  }

  &__controls {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    @include breakpoint($mobile) {
      flex-direction: column;
      margin-left: 0;
      width: 100%;
    }
  }
  &__note {
    margin-bottom: 16px;
  }
  &__checkMark {
    font-size: 22px;
    text-align: right;
    margin-bottom: 16px;
    @include breakpoint($mobile) {
      order: 2;
      margin-bottom: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__galleryBtn {
    @include breakpoint($mobile) {
      order: 1;
    }
  }
  &__header {
    display: flex;
    width: 100%;
    padding-right: 30px;
    @include breakpoint($mobile) {
      margin-bottom: 8px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
}
