@import '../../../assets/scss/App.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 37px;
  flex-direction: column;
}
.calendar,
.time {
  width: 310px;
  max-width: 100%;
  // @include breakpoint($mobile) {
  //   width: 100%;
  // }
}
.calendar {
  margin-bottom: 16px;
  position: relative;
  @include breakpoint($mobile) {
    margin-bottom: 35px;
  }
}
