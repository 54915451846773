.quantity {
  &__container {
    display: flex;
    align-items: center;
  }
  &__number {
    font-size: 18px;
    margin: 0 16px;
    color: black;
  }
}
