.infoModal {
  &__heading {
    margin-bottom: 8px;
    color: black;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
  &__clientInfo {
    line-height: 22px;
    margin-bottom: 0;
  }
  &__contact {
    display: flex;
    align-items: center;
  }
  &__orderList {
    padding-left: 16px;
    margin-bottom: 0;
    li {
      line-height: 22px;
    }
  }
}
