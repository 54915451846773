@import '../../assets/scss/App.scss';

.footer {
  &--upper {
    padding: 56px 0 24px 0;
  }
  &__wrapper {
    margin: 0 auto;
    width: 100%;
    background-color: $gray;
  }
  &__container {
    text-align: center;
    padding: 0;
    background-color: $gray;
  }
  &__socials {
    display: flex;
    width: 123px;
    margin: 0 auto 100px;
    justify-content: space-between;
    align-items: center;
    @include breakpoint($mobile) {
      margin-bottom: 20px;
    }
  }

  &__copyrightItem {
    margin-right: 4px;
    color: black;
    // margin-bottom: 24px;
    display: inline-block;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__links {
    margin-bottom: 24px;
    a {
      text-decoration: underline;
      color: black;
      font-weight: $robotoBold;
      font-size: 14px;
    }
  }
  &__lower {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    @include breakpoint($mobile) {
      padding: 0 4px;
      flex-direction: column;
      text-align: center;
    }
  }
}

.jobApplicationSection {
  &__container {
    text-align: center;
  }
  &__heading {
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: 700;
  }
  &__desc {
    font-size: 16px;
    margin-bottom: 16px;
    color: black;
  }
  &__btn {
    margin-bottom: 32px;
  }
}
