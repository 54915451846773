@import '../../assets/scss/App.scss';

.short-info-form {
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
  }
}
