@import '../../assets/scss/App.scss';

.SignaturePad {
  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 1000;
  }
  &__section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
  &__canvas {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  &__leaveSignatureBtn {
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
  &__closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &__clearBtn {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
  &__saveBtn {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  &__logo {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  &__signaturePreviewContainer {
    display: inline-block;
    width: 300px;
    height: auto;
    background-color: white;
    @include breakpoint($tablet) {
      width: 100%;
    }
  }
  &__signatureImg {
    // margin-bottom: 16px;
    // width: 100%;
    max-width: 100%;
    width: auto;
    max-height: 200px;
    margin: 16px auto 32px;
    display: block;
  }
  &__previewSignatureControls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__previewSignatureBtn {
    width: 48%;
  }
}
