/* Breakpoints */

@mixin breakpoint($query) {
  $query-string: breakpoint-parse-query($query);

  @media #{$query-string} {
    @content;
  }
}

@function breakpoint-parse-query($query) {
  $length: length($query);
  $mq: "all ";

  // If it's a single number
  @if $length == 1 {
    @return $mq + "and (max-width: " + $query + ")";
  }

  // If we've got a string/number
  @if (
    type-of(nth($query, 1)) == "string" and type-of(nth($query, 2)) == "number"
  ) {
    @return $mq + "and (" + nth($query, 1) + ": " + nth($query, 2) + ")";
  }

  // If they're both numbers, we assume it's a double and roll with that
  @if (
    type-of(nth($query, 1)) == "number" and type-of(nth($query, 2)) == "number"
  ) {
    @return $mq + "and (min-width: " + nth($query, 1) + ") and (max-width: " +
      nth($query, 2) + " )";
  }

  // Else assume is a list of properly formatted mq
  @each $single-query in $query {
    $mq: $mq + "and (" + nth($query, 1) + ": " + nth($query, 2) + ")";
  }
  @return $mq;
}
