@import '../../../assets/scss/App.scss';

.contractors {
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 32px;
    width: 100%;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }
  &__tab {
    position: relative;
    width: 48%;
    margin: 0 1%;
    border-radius: 4px;
    border: 1px solid $gray3;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    &:last-child {
      margin-right: 0;
    }
    @include breakpoint($mobile) {
      width: 304px;
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
  &__avatar {
    margin-bottom: 8px;
    background-size: cover;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-position: center;
    background-color: $gray3;
  }
  &__name {
    font-weight: $robotoBold;
    // font-size: 16px;
    // line-height: 22px;
    color: black;
    // margin-bottom: 10px;
  }
  &__rate {
    margin-bottom: 24px;
  }
  &__noRate {
    margin-bottom: 16px;
  }
  &__btn {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
  }
  &__section {
    border: 1px solid $gray3;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
  }
  &__feedbackHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  &__feedbackClient {
    font-weight: $robotoBold;
    color: black;
  }
  &__feedbackDate {
    display: block;
    text-align: right;
    font-size: 12px;
  }
  &__selectBtn {
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
}
