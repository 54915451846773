@import '../../assets/scss/App.scss';

.jobApplication {
  &__checkboxList {
    display: flex;
    flex-direction: column;
  }
  &__termsContainer {
    border: 1px solid $gray3;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;
  }
  &__termsText {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
  }
  &__photo {
    text-align: center;
  }
  &__modalTitle {
    font-size: 24px;
  }
  &__modalContent {
    font-size: 15px;
  }
  &__description {
    margin-bottom: 32px;
  }
  &__heading {
    font-size: 42px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 32px;
  }
  &__subHeading {
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 8px;
  }
}
