@import '../../../assets/scss/App.scss';

.service {
  &__select {
    width: 100%;
    margin-top: 16px;
  }
  &__btnContainer {
    justify-content: space-between;
  }
  &__summary {
    font-weight: $robotoBold;
    color: black;
    &--summaryClosed {
      margin-top: 8px;
      border-top: 1px solid $gray3;
      padding-top: 8px;
    }
  }
  &__summaryItem {
    margin-bottom: 0;
    line-height: 18px;
    font-size: 14px;
    @include breakpoint($mobile) {
      font-size: 12px;
    }
  }
}
