@import '../../assets/scss/App.scss';

.info {
  &__container {
    margin-bottom: 24px;
  }
  &__name {
    color: black;
    font-weight: $robotoBold;
  }
  &__name,
  &__desc {
    margin-bottom: 8px;
  }
}
