.PreviewUploadImage {
  &__imageContainer {
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__button {
    cursor: pointer;
  }
  &__label {
    font-size: 14px;
    margin-bottom: 0;
  }
  &__info {
    font-size: 12px;
    line-height: 1;
  }
}
