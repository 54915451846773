@import '../../assets/scss/App.scss';

.navigation {
  display: flex;
  margin-left: auto;
  align-items: center;
  &__logo {
    margin-left: 0;
    display: inline-block;
  }

  &__container {
    margin-top: 16px;
  }
  &__link {
    color: $black;
    display: block;
    height: 100%;
    padding: 2px 10px;
    text-decoration: none;
    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
      color: $black;
    }
    @include breakpoint($mobile) {
      display: none;
    }
  }
  &__linkMobile {
    color: $black;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    text-decoration: none;
    margin-bottom: 12px;
    &:focus {
      text-decoration: none;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__icon {
    font-size: 20px;
    margin-right: 16px;
  }
  &__langMobile {
    display: none;
  }
}
.active {
  text-decoration: underline;
}
.activeMobile {
  color: $black;
}
