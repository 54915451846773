@import '../../assets/scss/App.scss';

.scheduler {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__day {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint($mobile) {
      flex-direction: column;
      border: 1px solid $gray3;
      padding: 8px;
      border-radius: 4px;
      margin-bottom: 12px;
    }
  }
  &__dayName {
    width: 150px;
    display: inline-block;
    @include breakpoint($mobile) {
      width: 100%;
      font-size: 16px;
    }
  }
  &__switch {
    margin-right: 16px;
    @include breakpoint($mobile) {
      margin-right: 0;
    }
  }
  &__hours {
    display: inline-block;
    @include breakpoint($mobile) {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  &__addButton {
    float: right;
  }
  &__row {
    @include breakpoint($mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 16px;
    }
  }
  &__select {
    width: 120px;
    margin: 0 4px;
    @include breakpoint($mobile) {
      width: 100px;
    }
  }
}
