.ant-select-selection__placeholder {
  color: $dimGray;
  font-size: 16px;
  line-height: 22px;
  font-weight: $robotoRegular;
}
.ant-btn {
  border-color: $orange;
  color: $orange;
  &:focus {
    border-color: $orange;
    color: $orange;
  }
  &:hover {
    background-color: $orange;
    border-color: $orange;
    color: white;
  }
}
.ant-btn-primary {
  background-color: $orange;
  border: $orange;
  color: white;
  &:focus {
    background-color: $orange;
    border: $orange;
    color: white;
  }
}
.ant-select-selection-selected-value {
  color: $dimGray;
  font-size: 16px;
  font-weight: $robotoRegular;
}
.ant-form-item-label {
  line-height: 1.5;
  @include breakpoint($mobile) {
    // padding: 0;
    padding-bottom: 4px;
  }
  label {
    font-size: 14px;
    color: $grayish;
  }
}
.ant-form-item-required {
  font-weight: $robotoRegular;
  line-height: 22px;
}

.ant-form-item {
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.ant-skeleton-paragraph {
  padding-left: 0;
}
.ant-select-selection {
  border-color: $gray3;
}
.ant-input {
  border-color: $gray3;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  color: $gray3;
}
.ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
  .ant-upload {
    div {
      border-radius: 50%;
    }
  }
}
.ant-input-number {
  border-color: $gray3;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple
  .ant-select-selection__rendered
  > ul
  > li {
  height: 35px;
  line-height: 33px;
  background-color: $orange;
  color: white;
  &:last-of-type {
    background-color: transparent;
  }
}
.ant-select-selection--multiple
  .ant-select-selection__choice__remove {
  color: white;
  &:hover {
    color: white;
  }
}
.ant-tabs-nav {
  .ant-tabs-tab {
    margin-right: 18px;
    padding: 16px;
  }
}
.ant-result {
  @include breakpoint($mobile) {
    padding-top: 100px;
  }
}
.ant-row {
  &:last-of-type {
    margin-bottom: 0;
  }
}
.ant-dropdown {
  width: 120px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  padding: 8px 16px;
}
.ant-pagination {
  li {
    margin-bottom: 8px;
  }
  &-next,
  &-prev {
    .ant-pagination-item-link {
      font-size: 18px;
    }
  }
}

.ant-modal-body {
  padding: 16px;
}
.ant-list-item-meta-avatar {
  @include breakpoint($mobile) {
    margin-right: 0;
  }
}
.ant-result-title {
  @include breakpoint($mobile) {
    line-height: 1.2;
  }
}
.ant-list-item-action {
  display: flex;

  @include breakpoint($mobile) {
    display: flex;
    width: 100%;
    margin-left: 0;
  }
  li {
    @include breakpoint($mobile) {
      flex: 1;
      display: block;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}
.ant-drawer-body {
  padding: 16px 24px;
}
.ant-form-item-control {
  line-height: 22px;
}
.ant-list-item-action-split {
  display: none;
}
.ant-btn-icon-only.ant-btn-lg {
  padding: 0 8px;
}
.ant-modal-title {
  font-weight: 700;
  font-size: 18px;
  color: black;
}
.ant-modal-header {
  padding: 16px;
}
.ant-rate {
  line-height: 1;
}
.ant-upload.ant-upload-select-picture-card {
  margin-right: 0;
  background-color: white;
}
.ant-form-item-required {
  line-height: 1;
}
.ant-select-search--inline {
  .ant-select-search__field {
    @include breakpoint($mobile) {
      // padding-left: 0;
    }
  }
}
.ant-select-selection__clear {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 22px;
}
// upload image multiple with prefix class
.UploadImageMultiple-picture-card-wrapper {
  zoom: 1;
  width: 100%;

  .ant-upload-list-picture-card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .ant-upload-list-item {
    float: none;
    padding: 4px;
    @include breakpoint($mobile) {
      width: 30%;
      // height: auto;
      margin: 0 1.6% 1.6% 1.6%;
      // padding-top: 100%;
    }
  }
  .ant-upload-list-item-actions {
    height: 100%;
    width: 100%;
    a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.UploadImageMultiple-select-picture-card {
  text-align: right;
  // margin-top: 8px;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  transform: scale(1.5);
  transform-origin: center;
}
.ant-modal-close-x {
  color: black;
}
.ant-form-explain {
  margin-top: 0;
}
.ant-checkbox-group-item {
  margin-bottom: 4px;
}
