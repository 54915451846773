@import '../../assets/scss/App.scss';

.dashboard {
  &__name {
    @include breakpoint($mobile) {
      margin-bottom: 24px;
    }
  }
  &__title {
    display: flex;
    font-weight: 700;
    @include breakpoint($mobile) {
      flex-direction: column;
      font-size: 16px;
      text-align: center;
      margin: 8px 0;
      align-items: center;
    }
  }
  &__titleContainer {
    display: flex;
    margin-bottom: 16px;
    @include breakpoint($mobile) {
      flex-direction: column;
      // margin-bottom: 24px;
    }
  }
  // &__id {
  //   font-weight: 700;
  //   @include breakpoint($mobile) {
  //     font-size: 16px;
  //   }
  // }
  // &__meta {
  //   @include breakpoint($mobile) {
  //     align-items: center;
  //     width: 100%;
  //   }
  // }
  // &__divider {
  //   margin: 0 8px;
  // }
  &__description {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 8px;
    width: 100%;
    @include breakpoint($mobile) {
      width: 100%;
      // margin-bottom: 8px;
      flex-direction: column;
    }
    p {
      margin-bottom: 0;
    }
  }
  // &__action {
  //   @include breakpoint($mobile) {
  //     margin-left: auto;
  //   }
  // }
  &__item {
    margin-bottom: 8px;
    display: flex;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include breakpoint($tablet) {
      flex-wrap: wrap;
    }
    @include breakpoint($mobile) {
      // padding-bottom: 8px;
      ul {
        li {
          flex: 1;
          button {
            width: 100% !important;
            // flex: 1;
          }
        }
      }
    }
    .ant-list-item-action {
      @include breakpoint($mobile) {
        width: 100%;
      }
    }
  }
  // &__pagination {
  //   margin-top: 16px;
  //   text-align: center;
  // }
  &__btn {
    padding: 0 24px;
    @include breakpoint($mobile) {
      padding: 0 8px;
      width: 100%;
    }
  }
  &__select {
    width: 120px;
    margin-left: auto;
    display: block;
    margin-bottom: 16px;
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
  &__contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    @include breakpoint($mobile) {
      // text-align: center;
    }
  }
  &__rate {
    margin-left: 0;
    font-size: 16px;
    @include breakpoint($mobile) {
      margin-right: 0;
      width: 150px;
    }
  }
  // new styles
  &__cashierItem,
  &__clientItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__clientName,
  &__partnerName {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: $robotoBold;
    line-height: 22px;
    color: black;
    margin-bottom: 16px;
  }
  &__partnerName {
    margin-bottom: 0;
    @include breakpoint($mobile) {
      margin-bottom: 16px;
    }
  }
  &__clientOrder {
    margin-left: auto;
    width: 50%;
    @include breakpoint($mobile) {
      width: 100%;
    }
  }
  &__clientHeader {
    display: flex;
    width: 100%;
    align-items: flex-start;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }
  // &__itemList {
  //   list-style-type: disc;
  //   padding-left: 16px;
  //   font-size: 14px;
  //   color: #747474;
  //   li {
  //     line-height: 22px;
  //   }
  // }
  &__itemList {
    list-style-type: disc;
    padding-left: 0;
    font-size: 14px;
    color: #747474;
    li {
      line-height: 22px;
    }
  }
  &__itemFooter {
    display: flex;
    width: 100%;
    // margin-top: 8px;
    justify-content: space-between;
    @include breakpoint($mobile) {
      // align-items: center;
      flex-direction: column;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    @include breakpoint($mobile) {
      margin-left: 0;
    }
    button {
      padding: 0 24px;
      margin-right: 8px;
      &:last-of-type {
        margin-right: 0px;
      }
      @include breakpoint($mobile) {
        flex: 1;
        padding: 0 8px;
        flex-basis: 100%;
      }
    }
  }
  &__price {
    font-weight: $robotoBold;
    font-size: 20px;
    line-height: 22px;
    color: black;
    @include breakpoint($mobile) {
      margin-left: 0;
      display: block;
      margin-bottom: 16px;
    }
  }
  &__modalHeader {
    margin-bottom: 8px;
  }
  &__contractorInfo {
    display: flex;
    @include breakpoint($mobile) {
      margin-bottom: 8px;
      width: 100%;
      flex-direction: column;
    }
    &--partner {
      @include breakpoint($mobile) {
        width: 100%;
      }
    }
    &--client {
      @include breakpoint($mobile) {
        border-bottom: 1px solid $gray3;
        padding-bottom: 8px;
      }
    }
  }
  &__contractorBasics {
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 16px;
    @include breakpoint($mobile) {
      margin-left: 0;
    }
  }
  &__avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include breakpoint($mobile) {
      width: 70px;
      height: 70px;
    }
  }
  // contractor list with actions
  &__dashboardList {
    border-bottom: 1px solid $lightGray;
    padding: 0 0 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }
  &__dashboardListActions {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    @include breakpoint($mobile) {
      margin-left: 0;
    }
    button {
      margin-left: 16px;
      &:first-of-type {
        margin-left: 0;
      }
    }
    &--partner {
      @include breakpoint($mobile) {
        width: 100%;
        button {
          flex: 1;
        }
      }
    }
  }
  &__productName {
    font-weight: $robotoBold;
    flex: 1;
    color: black;
    margin-bottom: 0;
  }
  &__icon {
    margin-right: 8px;
  }
  &__contactInfo {
    display: flex;
    align-items: center;
    a {
      font-size: 14px;
    }
  }
  &__centerBtn {
    text-align: center;
    margin-top: 16px;
  }
  &__boothmanBtn {
    height: 60px;
    width: 300px;
    margin: 30vh auto 0 auto;
    display: block;
    max-width: 100%;
  }
}
