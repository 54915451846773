@import '../../assets/scss/App.scss';

.platformInformation {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }
  &__box {
    width: 210px;
    margin-bottom: 0;
    @include breakpoint($mobile) {
      width: 100%;
      order: 2;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__orderNumberWrapper {
    text-align: center;
    @include breakpoint($mobile) {
      // text-align: initial;
      order: 1;
      margin-bottom: 16px;
    }
  }
  &__heading {
    line-height: 1.2;
    font-size: 16px;
    font-weight: 700;
  }
}
