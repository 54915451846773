.Upload-multiple {
  &__container {
    margin-bottom: 16px;
  }
  &__uploadMessage {
    font-size: 14px;
  }
  &__previewImage {
    width: 100%;
  }
}
