.ContractorReport {
  &__heading {
    margin-bottom: 16px;
  }
  &__itemContainer {
    margin-bottom: 16px;
  }
  &__finishBtn {
    margin: 0 auto;
    display: block;
  }
}
