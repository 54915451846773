@import '../../../assets/scss/App.scss';

.burger {
  &__container {
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    height: 16px;
    padding: 16px 8px;
    display: flex;
    box-sizing: content-box;
    @include breakpoint($mobile) {
      display: flex;
    }
  }
  &__line {
    width: 20px;
    height: 2px;
    background-color: $black;
  }
}
