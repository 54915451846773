@import './assets/scss/App.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: white;

  // overflow-y: hidden;
}

// ios reset
textarea,
select,
input,
input[type='text'],
input[type='button'],
input[type='submit'] {
  -webkit-appearance: none;
  border-radius: 3px;
}
body,
html,
#root {
  height: 100%;
}

h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: $robotoBold;
}

h2 {
  font-size: 24px;
  line-height: 24px;
  font-weight: $robotoBold;
}

h3 {
  font-size: 24px;
  line-height: 22px;
  margin-bottom: 0;
}

h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: $robotoRegular;
  margin-bottom: 0;
}

p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
}

// body {
//   overscroll-behavior-y: contain;
// }
