@import '../../assets/scss/App.scss';

.landing {
  &__wrapper {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto 0 auto;
    @include breakpoint($mobile) {
      margin: 56px auto 0 auto;
    }
  }
  &__btn {
    height: 60px;
    width: 300px;
    margin: 30vh auto 0 auto;
    display: block;
    max-width: 100%;
  }
}

.hero {
  &__wrapper {
    height: 234px;
    background-image: url('../../assets/images/Rectangle.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__heading {
    color: white;
    text-align: center;
    padding-top: 32px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  }
  &__box {
    padding: 40px 40px 24px;
    background-color: #f5f5f5;
  }
  &__subheading {
    color: black;
    text-align: center;
    margin-bottom: 16px;
  }
  &__text {
    color: black;
    text-align: center;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 22px;
  }
  &__button {
    height: 60px;
    width: 300px;
    max-width: 100%;
  }
  &__buttonContainer {
    display: flex;
    justify-content: center;
  }
}

.how {
  &__container {
    padding: 32px 0;
    font-weight: $robotoRegular;
  }
  &__heading {
    color: black;
    text-align: center;
    margin-bottom: 62px;
  }
  &__itemContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 60px auto;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
  }
  &__item {
    // background-color: white;
    position: relative;
    padding: 80px 32px 32px 32px;
    // box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.14);
    width: 30%;
    @include breakpoint($mobile) {
      padding: 80px 32px;
      width: 100%;
      // margin: 0 auto 32px auto;

      height: 345px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__icon {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__subheading {
    color: black;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: $robotoRegular;
    line-height: 24px;
  }
  &__desc {
    color: black;
    font-weight: $robotoRegular;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }
}
