@import '../../assets/scss/App.scss';

.selectService {
  &__controls {
    display: flex;
    justify-content: space-between;
  }
}
.summary {
  &__container {
    width: 100%;
  }
}
.section {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 300ms ease;
    cursor: pointer;
  }
  &__name {
    text-align: left;
    font-size: 25px;
    font-weight: $robotoBold;
    margin: 0;
    transition: all 300ms ease;
  }
  &__icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
.order {
  &__block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &:first-of-type {
      padding-top: 8px;
    }
    &:last-of-type {
      .order__info {
        margin-bottom: 0;
      }
    }
    &--noLine {
      border-bottom: 0;
      padding: 0;
    }
    &--active {
      border-bottom: 1px solid $gray3;
      padding: 24px 0;
    }
  }
  &__duration,
  &__price {
    font-size: 16px;
    line-height: 22px;
    font-weight: $robotoRegular;
  }
  &__name {
    width: 100%;
    font-weight: $robotoBold;
    color: black;
    line-height: 22px;
    font-size: 14px;
    @include breakpoint($tablet) {
      width: 300px;
    }
    @include breakpoint($mobile) {
      width: auto;
    }
  }
  &__info {
    font-size: 16px;
    display: flex;
    width: 100%;
    padding-left: 0;
    padding-left: 16px;
    margin-bottom: 8px;
    flex-direction: column;
    &:hover {
      cursor: pointer;
    }
    &--noPadding {
      padding-left: 0;
      margin-bottom: 0;
    }
  }

  &__edit {
    font-size: 18px;
    color: black;
  }
  &__productName {
    line-height: 18px;
    margin-bottom: 4px;
    font-size: 16px;
  }
}
