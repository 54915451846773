@import '../../assets/scss/App.scss';

.section {
  margin-bottom: 16px;
  position: relative;
  padding: 16px;
  border: 1px solid $gray3;
  border-radius: 4px;
  user-select: none;
  background-color: white;
  &__name {
    font-weight: $robotoBold;
    color: $black;
    display: block;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
.label {
  font-size: 14px;
  color: $grayish;
  font-weight: $robotoRegular;
  &__container {
    text-align: left;
    margin-bottom: 11px;
  }
}
.input {
  &__wrapper {
    // margin-bottom: 16px;
    display: flex;
  }
}
