@import '../../assets/scss/App.scss';

.HorizontalLine {
  &__container {
    height: 1px;
    background-color: $gray3;
    width: 100%;
    position: relative;
  }
  &__text {
    position: absolute;
    top: -14px;
    background-color: $white;
    padding: 2px 16px;
    font-size: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
}
