@import '../../assets/scss/App.scss';

.auth {
  &__controls {
    display: flex;
    justify-content: space-between;
  }
  &__loginControls {
    display: flex;
    text-align: center;
    flex-direction: column;
  }
  &__forgot {
    margin-bottom: 8px;
    display: inline-block;
    @include breakpoint($mobile) {
      font-size: 12px;
    }
  }
  &__heading {
    margin-bottom: 8px;
  }
  &__button {
    display: block;
    &--fixed {
      margin: 0 auto;
      width: 174px;
    }
  }
  &__desc {
    font-weight: $robotoRegular;
    color: $lightBlack;
    margin-bottom: 8px;
  }
  &__terms {
    font-weight: $robotoRegular;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 22px;
    color: $lightBlack;
    display: inline-block;
    // margin-left: 16px;
    margin-bottom: 16px;
  }
  &__socialHeader {
    margin-bottom: 8px;
  }
  &__socials {
    display: flex;
    // margin-bottom: 16px;
    justify-content: space-between;
    @include breakpoint($mobile) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__socialBtn {
    width: 48%;
    font-weight: $robotoBold;
    font-size: 14px;
    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint($mobile) {
      margin-bottom: 8px;
      width: 100%;
      // width: 356px;
      // max-width: 100%;
    }
  }
  &__fb {
    background-color: #4267b2;
  }
  &__google {
    background-color: #4185f4;
  }
  &__registerBtn {
    margin: 0 auto;
  }
}
