@import '../../assets/scss/App.scss';

.orderOverview {
  &__itemList {
    line-height: 22px;
    list-style-type: disc;
    padding-left: 0;
  }
  &__item {
    line-height: 22px;
    font-size: 16px;
    display: flex;
    align-items: center;
    @include breakpoint($mobile) {
      flex-direction: column;
    }
    svg {
      width: 60px;
      height: auto;
      margin-bottom: 11px;
    }
  }
  &__productName {
    font-size: 16px;
    margin-bottom: 0;
    @include breakpoint($mobile) {
      margin-left: 0;
    }
  }
  &__name {
    color: $dimGray;
    font-size: 16px;
    line-height: 22px;
    display: block;
    margin-bottom: 0;
  }
  &__price {
    font-size: 28px;
    line-height: 22px;
    color: $dimGray;
    font-weight: $robotoBold;
  }
}
