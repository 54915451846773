.section {
  border: 1px solid $gray3;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid $gray3 !important;
  &:last-of-type {
    margin-bottom: 0;
  }
}
