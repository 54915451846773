@import '../../assets/scss/App.scss';

.ClientInfo {
  width: 50%;
  margin-bottom: 0;
  @include breakpoint($mobile) {
    width: 100%;
    margin-bottom: 8px;
  }
}
