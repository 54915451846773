@import '../../assets/scss/App.scss';

.paymentIcons {
  // height: 34px;
  margin: 0 4px;
  position: relative;
  @include breakpoint($mobile) {
    margin-bottom: 8px;
  }
  &__container {
    padding: 16px 8px;
    display: flex;
    align-items: center;
    @include breakpoint($mobile) {
      // justify-content: space-between;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__left {
    margin-right: auto;
    @include breakpoint($mobile) {
      margin-right: 0;
      // margin-bottom: 8px;
    }
  }
  &__right {
    margin-left: auto;
    @include breakpoint($mobile) {
      margin-left: 0;
    }
  }
  &__center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__visaverified,
  &__mastercardsecurecode {
    width: 60px;
    height: 38px;
    // max-height: 30px;
    background-color: white;
    padding: 4px;
  }

  &__master,
  &__maestro,
  &__visa {
    width: 60px;
    height: 38px;
  }
  &__chipCard {
    // width: 50px;
    height: 38px;
  }
}
