.DayPicker-Month {
  margin: 0;
  width: 100%;
}
.DayPicker-Caption {
  padding: 0;
  text-align: center;
  color: $dimGray;
  font-weight: $robotoMedium;
}
.DayPicker-Months {
  padding: 8px;
  &:focus {
    outline: none;
  }
}
.DayPicker-Weekday {
  color: $dimGray;
}
.DayPicker-Day {
  color: $dimGray;
  font-size: 14px;
  border-radius: 4px;
  &--disabled {
    color: $lightGray;
  }
}
.DayPicker-wrapper {
  padding: 0;
  &:focus {
    outline: none;
  }
}
.DayPicker-NavButton {
  top: 5px;
  color: $dimGray;
  height: 1.75em;
  width: 1.75em;
  &--prev {
    left: 11px;
  }
  &--next {
    right: 11px;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $orange;
  color: $white;
}
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: $orange;
  color: $white;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  &:hover {
    background-color: $orange;
    color: $white;
  }
}
.disable-arrow {
  .DayPicker-NavButton--prev {
    display: none;
  }
}
